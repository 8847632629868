.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}

/* .hero-mid{
    width: 100%;
    height: 70vh;
    position: relative;
} */
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text{
    position: absolute;
    top: 50%;
    background: rgba(153, 149, 149, 0);
    /* left: 50%; */
    /* transform: translate(50%); */
    /* width: 100%; */
}

.hero-text h1{
    font-size: 3rem;
    font-weight: 800;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text .show{
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
}

@media screen and (max-width: 555px) {
    .hero-text h1{
        padding: 10px 20px;
    }

    
.hero-text .show{
 
    padding: .6rem 1.1rem;
    border-radius: 6px;
    font-size: 1rem;

   
 
}
    
}