.navbars{
   /* display: flex; */
   justify-content: space-between;
   align-items: center;
   padding: 0 30px;
   box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
   /* background-color: #fff; */
   width: 98%;
   height: 60px;
   border-radius: 13px;
   position: fixed;
   top:10px;
   left: 50%;
   transform: translate(-50%);
   z-index: 9999;
   
    /* background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top:20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999; */
}



.navbar-logo{
    color: #222;
    font-size: 1.4rem;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap:10px ;
    list-style: none;
    align-items: center ;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.nav-links{
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links i{
    padding-right: 10px;
}
.nav-links:hover{
    background-color: #01959a;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}
.nav-links-mobile{
    display:none;
}
/* 
button{
    padding:0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
} */

/* button:hover{
    background-color: #151516;
    color: #fff;
} */

.menu-icons{
    display: none;
}

@media screen and (max-width: 850px) {
    .navbars{
        z-index: 99;
    }
    /* .nav-menu{
        display: grid;
        grid-template-columns: repeat(6, auto);
        grid-gap:10px ;
        list-style: none;
        align-items: center ; 
    } */
    
}