table {
    width: 100%;
    /* border-collapse: table table-bordered; */
  }
  th, td {
    border: 1px solid #ddd;
    padding: 2px;
    text-align: center; /* Center align text */
    vertical-align: middle; /* Vertically center align content */
  }
  th {
    background-color: #f2f2f2;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  /* Set width for the Calling Ports and Est. Date columns */
  /* .calling-ports, .est-date {
    width: 50%;
  } */
  /* Set the width and height of td elements in the specific row */
  .specific-row td {
    
    width: 100px; 
    height: 50px; 
  }