* {
    margin: 0;
    padding: 0;
    /* font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif; */
  }
  
  .main {
    width: 100vw;
    height: 100vh;
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media screen {
    * {
      margin: 0;
      padding: 0;
      /* font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif; */
    }
  
    .main {
      width: 100vw;
      height: 100vh;
    }
  
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  * {
    margin: 0;
    padding: 0;
    /* font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif; */
    box-sizing: border-box;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 102.6%;
    background-color: #494949cc;
    z-index: 0; /* Send the overlay behind the form */
  }
  