.navbar1{
    background-color: rgb(174, 202, 215);
}
.nav-linkss{
    text-decoration: none;
    color:  #0e0e0e;
    font-size: 1.0rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    font-family:'Times New Roman', Times, serif

}

.head{
    font-family:'Times New Roman', Times, serif;
}