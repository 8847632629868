.wrapper {
    position: absolute;
    width: 28%;
    /* background: rgba(235, 235, 235, 0.5); Semi-transparent white background */
    height: 45%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 20px;
    border-radius: 10px;
    /* backdrop-filter: blur(20px); Add a blur effect to the background */
    z-index: 1; /* Bring the form to the front */
  }
  
  .wrapper h1 {
    color: lightblue;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .input-box {
    width: 100%;
    margin-bottom: 15px;
    color: #fff;
  }
  
  .input-box input {
    width: 100%;
    height: 40px;
    background: transparent;
    outline: none;
    border: 2px solid;
    border-radius: 20px;
    padding: 0 15px;
    color: #fff;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-size: 16px;
  }
  
  .input-box input::placeholder {
    color: #fff;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-size: 16px;
  }
  
  .input-box input[type="password"] {
    color: #fff;
  }
  
  .input-box .icon {
    position: absolute;
    right: 30px; /* Adjust as needed */
    /* top: 50%; */
    transform: translateY();
    font-size: 16px;
    color: #fff; /* Set the icon color */
  }
  
  .input-box .icon {
    position: absolute;
    right: 30px; /* Adjust as needed */
    /* top: 40%; */
    transform: translateY(60%);
    font-size: 16px;
    color: #fff; /* Set the icon color */
  }
  
  .remember-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .remember-forgot label {
    color: #fff;
    font-size: 14px;
  }
  
  .remember-forgot a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .remember-forgot a:hover {
    text-decoration: underline;
    color: rgb(42, 42, 255);
  }
  
  .remember-forgot label,
  .register-link {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
  
  .register-link a,
  .remember-forgot a {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
  
  /* button {
    width: 100%;
    height: 40px;
    background: lightblue;
    border: none;
    outline: none;
    border-radius: 20px;
    color: black;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-size: 16px;
    cursor: pointer;
  } */
  
  .register-link {
    color: red;
    font-size: 14px;
    text-align: center;
    margin: 5%;
  }
  
  .register-link a {
    color: white;
    text-decoration: none;
  }
  
  .register-link p {
    color: white;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    /* text-decoration: none; */
  }
  
  .register-link a:hover {
    text-decoration: underline;
    color: rgb(42, 42, 255);
  }
  